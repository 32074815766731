import { defineComponent, ref, reactive, toRaw, computed, toRefs, onMounted, watch } from 'vue';
import useTablePagination from '@/hooks/pagination';
import printJS from 'print-js';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import uploadExl from '@/components/uploadExl.vue';
import { selectBillByPagination, selectAllBill } from '@/api/material';
import { useStore } from 'vuex';
import Cookies from "js-cookie"; //表头

const columns = [{
  title: '材料名称',
  dataIndex: 'materialsName'
}, {
  title: '规格',
  dataIndex: 'materialsSpecs'
}, {
  title: '供应商',
  dataIndex: 'supplierName'
}, {
  title: '进场数量',
  dataIndex: 'approachNum',
  sorter: (a, b) => a.approachNum - b.approachNum
}, {
  title: '出库数量',
  dataIndex: 'outboundNum',
  sorter: (a, b) => a.outboundNum - b.outboundNum
}, {
  title: '退场数量',
  dataIndex: 'exitNum',
  sorter: (a, b) => a.exitNum - b.exitNum
}, {
  title: '库存数量',
  dataIndex: 'inventoryNum',
  sorter: (a, b) => a.inventoryNum - b.inventoryNum
}, {
  title: '存放地址',
  dataIndex: 'materialsLocationList',
  slots: {
    customRender: 'materialsLocationList'
  }
}];
const columns1 = [{
  title: '地址',
  dataIndex: 'physicsLocation'
}, {
  title: '数量',
  dataIndex: 'materialsNum'
}];
export default defineComponent({
  components: {
    uploadExl
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      materialsName: "",
      materialsSpecs: "",
      supplierName: ""
    }); //点击查询

    const onSubmit = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]);
    const loading = ref(false); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    }; // const tableAllData = ref([])


    const getAllTableData = () => {
      return new Promise((resolve, reject) => {
        selectAllBill().then(res => {
          resolve(res.data);
        });
      }).catch(error => {
        console.log(error);
        reject(error.data);
      });
    }; //打印


    const handlePrint = () => {
      getAllTableData().then(res => {
        let data = [];

        for (let i = 0; i < res.length; i++) {
          data.push({
            materialsName: res[i].materialsName,
            materialsSpecs: res[i].materialsSpecs,
            supplierName: res[i].supplierName,
            approachNum: res[i].approachNum,
            outboundNum: res[i].outboundNum,
            exitNum: res[i].exitNum,
            inventoryNum: res[i].inventoryNum,
            physicsLocation: res[i].physicsLocation,
            materialsNum: res[i].materialsNum
          });
        }

        printJS({
          printable: data,
          properties: [{
            field: 'materialsName',
            displayName: '材料名称',
            columnSize: 1
          }, {
            field: 'materialsSpecs',
            displayName: '规格',
            columnSize: 1
          }, {
            field: 'supplierName',
            displayName: '供应商',
            columnSize: 1
          }, {
            field: 'approachNum',
            displayName: '进场数量',
            columnSize: 1
          }, {
            field: 'outboundNum',
            displayName: '出库数量',
            columnSize: 1
          }, {
            field: 'exitNum',
            displayName: '退场数量',
            columnSize: 1
          }, {
            field: 'inventoryNum',
            displayName: '库存数量',
            columnSize: 1
          }, {
            field: 'physicsLocation',
            displayName: '存放地址',
            columnSize: 1
          }, {
            field: 'materialsNum',
            displayName: '存放数量',
            columnSize: 1
          }],
          type: 'json',
          // header: '标题',
          // 样式设置
          gridStyle: 'border: 1px solid #3971A5;',
          gridHeaderStyle: 'color: red;  border: 1px solid #3971A5;'
        });
      });
    }; //下载


    const handleDownExcel = () => {
      getAllTableData().then(res => {
        require.ensure([], () => {
          const {
            export_json_to_excel3
          } = require("@/assets/excel/Export2Excel");

          const tHeader = ["材料名称", "规格", "供应商", "进场数量", "出库数量", "退场数量", "库存数量", "存放地址", ""]; // 导出的excel表头名信息

          const tHeader2 = ["", "", "", "", "", "", "", "地址", "数量"]; // 导出的excel表头名信息

          const filterVal = ["materialsName", "materialsSpecs", "supplierName", "approachNum", "outboundNum", "exitNum", "inventoryNum", "physicsLocation", "materialsNum"]; // 导出的excel表头字段名，需要导出表格字段名

          const list = res;
          const data = formatJson(filterVal, list);
          const merges = ["A1:A2", "B1:B2", "C1:C2", "D1:D2", "E1:E2", "F1:F2", "G1:G2", "H1:I1"];
          export_json_to_excel3(tHeader, tHeader2, data, "材料清单", merges); // 导出的表格名称，根据需要自己命名
        });
      });
    }; //格式转换


    const formatJson = (filterVal, jsonData) => {
      return jsonData.map(v => filterVal.map(j => v[j]));
    };

    const isSuccess = val => {
      getTableData();
    }; //获取表格数据


    const getTableData = async () => {
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        supplierName: formState.supplierName,
        materialsName: formState.materialsName,
        materialsSpecs: formState.materialsSpecs
      };
      loading.value = true;

      try {
        const res = await selectBillByPagination(param);

        if (res.code === 200) {
          const arr = [];
          tableData.value = [];
          if (res.data.list) res.data.list.forEach(item => {
            arr.push({ ...item,
              key: item.id
            });
          });
          Object.assign(tableData.value, arr);
          total.value = res.data.countSize;
          current.value = res.data.nowPage;
          pageSize.value = res.data.pageSize;
          loading.value = false;
        } else {
          loading.value = false;
          message.error(res.message);
        }
      } catch {
        loading.value = false;
      }
    };

    const store = useStore(); //是否是管理员

    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin); //是否可以初始导入

    const isFirstImport = computed(() => Number(Cookies.get('isFirstImport'))); //存放地址

    const tableDataDz = ref([]);
    const visible = ref(false);

    const toView = val => {
      visible.value = true;
      tableDataDz.value = val.materialsLocationList;
    };

    onMounted(() => {
      getTableData();
    });
    return {
      formRef,
      formState,
      onSubmit,
      resetForm,
      paginationConfig,
      columns,
      tableData,
      loading,
      onPageChange,
      handlePrint,
      handleDownExcel,
      isSuccess,
      isCompanyAdmin,
      isFirstImport,
      toView,
      columns1,
      tableDataDz,
      visible
    };
  }

});